import { Guid } from './guid.model';

export class NewTester implements Roi.Data.Tester {

    Id: string;    
    Name: string;
    Info: string;
    Password: string;
    Companies: Roi.Data.Company[];
    Active: boolean;
    LastUpdate: Date;

    constructor(){
        this.Id = Guid.newGuid();
        this.LastUpdate = new Date(Date.now());
    }
}
