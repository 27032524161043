import { Token } from './token.model';


export class User extends Token {
    /**
     * Returns the parent Token class 
     */
    _token: Token;
    /**
     * Returnes the Base 64 encoded token
     */
    _tokenEncoded: string;
    /**
     * Returns the Unix time that the token cannot be used before
     */
    _tokenBegin: number;
    /**
     * Returns the Unix time of the token expiration
     */
    _tokenExpires: number;
    /**
     * Returns the date the token was issued
     */
    _tokinIssuedOn: Date;
    /**
     * Returns the string username
     */
    _username: string;
    /**
     * Returns the IDs of the groups (maybe the company IDs) the user belongs to
     */
    _groups: string[];
    
    constructor(private encoded: string, private newToken: Token){
        super();
        this._token = newToken;
        this._tokenEncoded = encoded;
        this._tokenBegin = newToken.nbf;
        this._tokenExpires = newToken.exp;
        this._tokinIssuedOn = new Date(newToken.iat);
        this._groups = newToken.groupsid;
    }
}
