export class Token {
    /**
     * groups (companies) the user belongs to
     */
    groupsid: [];
    /**
     * username
     */
    unique_name: string;
    /**
     * token not to be used before
     */
    nbf: number;
        /**
     * token expires on
     */
    exp: number;
        /**
     * token issued on
     */
    iat: number;
}
