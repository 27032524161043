import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { AuthenticationService } from './authentication.service'

@Injectable({
  providedIn: 'root'
})
export class HeaderService {

  constructor (private authService: AuthenticationService){
  }

  private httpOptions = {
      headers: new HttpHeaders({
          'Authorization': `Bearer ${this.authService.currentUserValue._tokenEncoded}`,
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
          'Accept': 'application/json'
      })
  };

  private cosmosHttpOptions = {
    headers: new HttpHeaders({
        'Authorization': `Bearer mdKXWHu7FgVhDaFxTnUzgn8FjikFTssQh7Hx0Y8kIn4M03Pa9PRgmgQcNPMQMcRvgLyt55gXcL4GwLshFGs5bg==` ,
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/query+json',
        'Accept': 'application/json',
        'x-ms-documentdb-isquery' : 'true',
        'x-ms-documentdb-query-enablecrosspartition' : 'true'
    })
};

  public get authHeader(){
      return this.httpOptions;
  }

  public get cosmosAuthHeader(){
    return this.cosmosHttpOptions;
  }
}