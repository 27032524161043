import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { AuthGuardService } from './services/auth-guard.service';
import { SearchComponent } from './search/search.component';
import { LoginComponent } from './login/login.component';
import { BasicComponent } from './reports/basic/basic.component';
import { AdminComponent } from './admin/admin.component';
import { DeviceComponent } from './admin/device/device.component';


//const routes: Routes = [];

export const appRoutes: Routes = [
  {
      path: '',
      component: HomeComponent,
      canActivate: [AuthGuardService]
  },
  {
      path: 'login',
      component: LoginComponent
  },
  {
      path: 'search',
      component: SearchComponent,
      canActivate: [AuthGuardService]
  },
  {
    path: 'reports/basic',
    component: BasicComponent,
    canActivate: [AuthGuardService]
  },
  {
      path: 'admin',
      component: AdminComponent,
      canActivate: [AuthGuardService]
  },
  {
    path: 'device',
    component: DeviceComponent,
    canActivate: [AuthGuardService]
},

  // otherwise redirect to home
  { path: '**', redirectTo: '' }
];

export const routing = RouterModule.forRoot(appRoutes);
