import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HeaderService } from './header.service';
import { ApiService } from './api.service';
import { KeyValue } from '@angular/common';
import { Observable, pipe } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TestService {

  constructor(
    private http: HttpClient,
    private headerService: HeaderService,
    private apiService: ApiService
  ) { }

  getTests(): Observable<KeyValue<string, Roi.Data.Test[]>>{
    return this.http.get<KeyValue<string, Roi.Data.Test[]>>(`${this.apiService.DataUrl()}/tests`, this.headerService.authHeader);
  }

  getTestsMax(): Observable<number> {
    return this.http.get<number>(`${this.apiService.DataUrl()}/tests/$count`, this.headerService.authHeader);
  }

  getYTD(): Observable<number> {
    return this.http.get<number>(`${this.apiService.DataUrl()}/tests/$count?$filter=DateTime lt 2020-01-01T00:00:00.00Z`, this.headerService.authHeader);
  }

  get6Mo(): Observable<number> {
    return this.http.get<number>(`${this.apiService.DataUrl()}/tests/$count?$filter=DateTime gt 2019-03-01T00:24:59.99Z`, this.headerService.authHeader);
  }

  get3Mo(): Observable<number> {
    return this.http.get<number>(`${this.apiService.DataUrl()}/tests/$count?$filter=DateTime gt 2019-06-01T00:24:59.99Z`, this.headerService.authHeader);
  }

  getDay(day: number, month: number, year: number): Observable<number> {
    return this.http.get<number>(`${this.apiService.DataUrl()}/tests/$count?$filter=year(DateTime) eq ${year} and month(DateTime) eq ${month} and day(DateTime) eq ${day}`, this.headerService.authHeader);
  }

  getMonth(month: number, year: number): Observable<number> {
    return this.http.get<number>(`${this.apiService.DataUrl()}/tests/$count?$filter=year(DateTime) eq ${year} and month(DateTime) eq ${month}`, this.headerService.authHeader);
  }

  getMonthByCompany(month: number, year: number, companyId: string): Observable<number> {
    return this.http.get<number>(`${this.apiService.DataUrl()}/tests/$count?$filter=year(DateTime) eq ${year} and month(DateTime) eq ${month} and companyId eq ${companyId}`, this.headerService.authHeader);
  }

  getYear(year: number): Observable<number> {
    return this.http.get<number>(`${this.apiService.DataUrl()}/tests/$count?$filter=year(DateTime) eq ${year}`, this.headerService.authHeader);
  }

  getYearByCompany(year: number, companyId: string): Observable<number> {
    return this.http.get<number>(`${this.apiService.DataUrl()}/tests/$count?$filter=year(DateTime) eq ${year} and companyId eq ${companyId}`, this.headerService.authHeader);
  }

  getMTD(): Observable<number> {
    return this.http.get<number>(`${this.apiService.DataUrl()}/tests/$count?$filter=DateTime gt 2019-08-03T00:24:59.99Z`, this.headerService.authHeader);
  }

  getWeek(): Observable<number> {
    return this.http.get<number>(`${this.apiService.DataUrl()}/tests/$count?$filter=DateTime gt 2019-08-27T00:24:59.99Z`, this.headerService.authHeader);
  }

  getToday(): Observable<number> {
    return this.http.get<number>(`${this.apiService.DataUrl()}/tests/$count?$filter=DateTime gt 2019-09-03T00:00:00.00Z`, this.headerService.authHeader);
  }

  getDateSpan(begin: string, end: string): Observable<number> {
    return this.http.get<number>(`${this.apiService.DataUrl()}/tests/$count?$filter=DateTime gt ${begin} and lt ${end}`, this.headerService.authHeader);
  }
}
