import { Component, OnInit, ViewChild, ElementRef, EventEmitter, Output } from '@angular/core';
import { AdminService } from '../services/admin.service';
import { TestService } from '../services/test.service';
//import { GoogleChartComponent, ChartErrorEvent } from 'angular-google-charts';
import { Chart } from 'chart.js';
import { FormControl } from '@angular/forms';

import {MomentDateAdapter} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import * as moment from 'moment';
// tslint:disable-next-line:no-duplicate-imports
import {Moment} from 'moment';
import { MatDatepicker, MatDatepickerInputEvent, MatDialog, MatDialogRef } from '@angular/material';
import {ProgressComponent} from '../progress/progress.component';
import { Observable } from 'rxjs';
import { ReportsService } from '../services/reports.service';

export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},

    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})



export class HomeComponent implements OnInit {
  
  dialogRef: MatDialogRef<ProgressComponent>;
  chartRef: any;

  selectCompany: boolean = false;
  selectedCompany: Roi.Data.Company = null;

  date = new FormControl(moment());
  filterByMonth: boolean = false;
  filterByDay: boolean = false;
  filterByDateRange: boolean = false;

  selectedMonth: number;
  selectedYear: number;

  reports: Roi.Data.ReportBundle[] = [];
  injurys: string[] = [];

  reports1: Roi.Data.ReportBundle[] = [];
  injurys1: string[] = [];

  chart: any;
  tests: Roi.Data.Test[];
  companies: Roi.Data.Company[] = [];

  chartType = {
    YearToDate: 'YearToDate',
    Month: 'Month',
  }

  selectedChartType: string = this.chartType.YearToDate;

  months = new Array('Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec');
  monthLength = new Array(31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31);

  injuryCount: number = 0;

  constructor(
    private adminService: AdminService,
    private testService: TestService,
    private elementRef: ElementRef,
    private dialog: MatDialog,
    private reportsService: ReportsService) { }

  ngOnInit() {

    this.testService.getTests().pipe().subscribe(t => {
      this.tests = t.value; 
    });
    this.adminService.getCompanies().pipe().subscribe(c => {
      this.companies = c.value;
      this.yearToDateByCompany();
    });

    this.getInjurys();

    //this.yearToDate();
    
  }

  openDialog(){
    this.dialogRef = this.dialog.open(ProgressComponent, {
      panelClass: 'transparent',
      disableClose: false
    });
  }

  closeDialog(){
    this.dialogRef.close();
  }

  clearSelectedCompany(){
    this.selectedCompany = null;
  }

  filterByCompany(){
    switch (this.selectedChartType){
      case this.chartType.Month:
        break;

      case this.chartType.YearToDate:
        this.yearToDate();
        break;
    }

  }

  month(){
    
    this.openDialog();
    var data = new Array();
    var labels = new Array();

    for (var i = 0; i < this.monthLength[this.selectedMonth]; i++){
      
      
      labels.push(`${i + 1}`);

      this.testService.getDay(i + 1, this.selectedMonth + 1, this.selectedYear).pipe().subscribe(t => {
        data.push(t);
        if (data.length === this.monthLength[this.selectedMonth]) {
          this.drawChart(labels, `Tests : ${this.months[this.selectedMonth]} / ${this.selectedYear}`, "line", data);
        }
      });
    }

  }

  monthByCompany(){
    this.openDialog();
  }

  yearToDate(){
    this.openDialog();
    var date = new Date();
    var monthIndex = date.getMonth() + 1;
    var year = date.getFullYear() -1 ;
    var labels = new Array();

    var data = new Array();

    for (var i = 0; i < 12; i++){
      
      labels.push(`${this.months[monthIndex]} ${year}`);

      if (this.selectedCompany){
        this.testService.getMonthByCompany(monthIndex + 1, year, this.selectedCompany.Id).pipe().subscribe(t => {
          data.push(t);
          if (data.length === 12){
            this.drawChart(labels, "Tests: Year to Date", "line", data);
          }
        });
      }
      else{
        this.testService.getMonth(monthIndex + 1, year).pipe().subscribe(t => {
          data.push(t);
          if (data.length === 12){
            this.drawChart(labels, "Tests: Year to Date", "line", data);
          }
        });
      }

      

      monthIndex++;

      if (monthIndex === 12){
        monthIndex = 0;
        year++;
      }
    }
  }

  yearToDateByCompany(){
    this.openDialog();
    var companyCount = this.companies.length;
    var labels = new Array();

    var date = new Date();
    var year = date.getFullYear();

    var data = new Array();
    for (var i = 0; i < companyCount; i++){
      labels.push(`${this.companies[i].Name}`);

      this.testService.getYearByCompany(year, this.companies[i].Id).pipe().subscribe(t => {
        data.push(t);
        if (data.length === this.companies.length){
          this.drawChart(labels, "Tests: Year to Date By Company", "bar", data);
        }
      });
    }
  }

  drawChart(labels: string[], legend: string, type: string, data: number[]) {
    this.closeDialog();
    var chartRef = this.elementRef.nativeElement.querySelector(`#mainChart`);
    if (this.chart) {
      this.chart.destroy();
    }
    this.chart = new Chart(chartRef, {
      type: type,
      data: {
        labels: labels,
        datasets: [
          {
            label: legend,
            data: data,
            borderColor: '#00AEFF',
            fill: false
          }
        ]
      },
      options: {
        legend: {
          display: true
        },
        scales: {
          xAxes: [{
            display: true
          }],
          yAxes: [{
            display: true,
            ticks: {
              beginAtZero: true,
              callback: function(value) {if (value % 1 === 0) {return value;}}
            }
          }],
        }
      }
    });
  }

  getCompanies() {
    this.adminService.getCompanies().pipe().subscribe(t => {
      //this.companies = [];
      //this.companies = t.value;
    });
    
  }

  getChildren() {
    
    this.adminService.getDevices().pipe().subscribe(t => {
      //this.devices = t.value;
    });
    this.adminService.getTesters().pipe().subscribe(t => {
      //this.testers = t.value;
    });
    

  }

  getInjurys(){
    // this.reportsService.getInjurys().pipe().subscribe(r => {
    //   this.reports = JSON.parse(r.value);
    //   for (var i = 0; i < this.reports.length; i++){
    //     if (this.reports[i].ReportData.InjuryFlag > 89){
    //       this.injurys.push(this.reports[i].ReportData.Uuid);
    //     }
    //   }
    // });
  }

  chosenYearHandler(normalizedYear: Moment) {
    this.date = new FormControl(moment());
    const ctrlValue = this.date.value;
    ctrlValue.year(normalizedYear.year());
    this.selectedYear = normalizedYear.year()
    this.date.setValue(ctrlValue);
  }

  chosenMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>) {
    const ctrlValue = this.date.value;
    ctrlValue.month(normalizedMonth.month());
    this.selectedMonth = normalizedMonth.month();
    this.date.setValue(ctrlValue);
    datepicker.close();
    this.filterByMonth = false;

    this.month();
  }

  chosenDayHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>) {
    datepicker.close();
    this.filterByDateRange = false; 
  }

}
