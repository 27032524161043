import { HistoryColumn } from './history-column.model';


export class ReportData {
    Uuid: Edm.String;
        Time: Edm.Int64;
        Company: Edm.String;
        TestId: Edm.String;
        OptionalId: Edm.String;
        Gender: Edm.String;
        Age: Edm.String;
        DominantHand: Edm.String;
        RightHandDominant: Edm.Boolean;
        TestDate: Edm.String;
        TestDateHist1: Edm.String;
        TestDateHist2: Edm.String;
        StartDate: Edm.String;
        TotalTests: Edm.Int32;
        LeftCognitiveReactionTime: Edm.Double;
        RightCognitiveReactionTime: Edm.Double;
        LeftCorrelation: Edm.Double;
        RightCorrelation: Edm.Double;
        LeftCorrelation2s: Edm.Double;
        LeftCorrelation3s: Edm.Double;
        RightCorrelation2s: Edm.Double;
        RightCorrelation3s: Edm.Double;
        LeftFatigueVariance: Edm.Double;
        RightFatigueVariance: Edm.Double;
        LeftMotorControl: Edm.Double;
        RightMotorControl: Edm.Double;
        LeftReactionTime: Edm.Double;
        RightReactionTime: Edm.Double;
        LeftSensoryControl: Edm.Double;
        RightSensoryControl: Edm.Double;
        LeftStrength: Edm.Double;
        RightStrength: Edm.Double;
        LeftStrengthRatio: Edm.String;
        RightStrengthRatio: Edm.String;
        LeftCognitiveReaction: Edm.Double;
        RightCognitiveReaction: Edm.Double;
        LeftMotorControl2s: Edm.Double;
        RightMotorControl2s: Edm.Double;
        LeftMotorControl3s: Edm.Double;
        RightMotorControl3s: Edm.Double;
        LeftPulse_2s: Edm.String;
        LeftPulse3_2s: Edm.String;
        LeftPulseForce3_2s: Edm.String;
        LeftPulse7_2s: Edm.String;
        LeftPulseForce7_2s: Edm.String;
        RightPulse_2s: Edm.String;
        RightPulse3_2s: Edm.String;
        RightPulseForce3_2s: Edm.String;
        RightPulse7_2s: Edm.String;
        RightPulseForce7_2s: Edm.String;
        LeftPulse_3s: Edm.String;
        LeftPulse3_3s: Edm.String;
        LeftPulseForce3_3s: Edm.String;
        LeftPulse7_3s: Edm.String;
        LeftPulseForce7_3s: Edm.String;
        RightPulse_3s: Edm.String;
        RightPulse3_3s: Edm.String;
        RightPulseForce3_3s: Edm.String;
        RightPulse7_3s: Edm.String;
        RightPulseForce7_3s: Edm.String;
        LeftSensoryControl2s: Edm.Double;
        RightSensoryControl2s: Edm.Double;
        LeftSensoryControl3s: Edm.Double;
        RightSensoryControl3s: Edm.Double;
        LeftHist1: HistoryColumn;
        RightHist1: HistoryColumn;
        LeftHist2: HistoryColumn;
        RightHist2: HistoryColumn;
        LeftInjuryEvidence: Edm.Double;
        RightInjuryEvidence: Edm.Double;
        InjuryFlag: Edm.Double;
        TestHistory: Edm.String;
}
