import { Component } from '@angular/core';
import { UiService } from './services/ui.service';
import { Router } from '@angular/router';
import { AuthenticationService } from './services/authentication.service';
import { User } from './models/user.model';
import { CompanyService } from './services/company.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Redoak';
  currentUser: User;
  showMenu = false;
  darkModeActive: boolean;
  companies: any;
  selectedCompanies: Observable<Roi.Data.Company[]>;

  constructor(
    public ui: UiService,
    private router: Router,
    private authenticationService: AuthenticationService,
    private companyService: CompanyService) {

     
      
  }

  ngOnInit() {
    this.ui.darkModeState.subscribe((value) => {
      this.darkModeActive = value;
    });

    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
    
  }

  // updateCompanies(){
  //   this.companyService.currentCompanies = this.selectedCompanies;
  // }

  toggleMenu() {
    this.showMenu = !this.showMenu;
  }

  modeToggleSwitch() {
    this.ui.darkModeState.next(!this.darkModeActive);
    this.toggleMenu();
  }

  logout() {
    this.authenticationService.logout();
    this.router.navigate(['/login']);
  }
}
