import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from './authentication.service';
import { HeaderService } from './header.service';
import { ApiService } from './api.service';
import { Router } from '@angular/router';
import { KeyValue } from '@angular/common';
import { Report } from '../models/report.model';

@Injectable({
  providedIn: 'root'
})
export class ReportService {
  public selectedTestId: string;
  constructor(private http: HttpClient,
    private authService: AuthenticationService,
    private headerService: HeaderService,
    private apiService: ApiService,
    private router: Router
    ) { }

    viewReport(testId: string){
      // logic to determine SLA
      this.router.navigate(['/reports/basic']);
      this.selectedTestId = testId;

      
    }

    viewOldReport(test: Roi.Data.Test){
      var date = new Date();
      var utcOffset = (-date.getTimezoneOffset()).toString();

      // until new report page is working
      window.open(`https://reports.redoakinstruments.com/reports/Full.aspx?type=full&id=${test.Id}&companyId=${test.CompanyId}&utcoffset=${utcOffset}`);
    }

    getReport(){
      return this.http.get<Report>(`${this.apiService.DataUrl()}/reports/${this.selectedTestId}`, this.headerService.authHeader);
    }
}
