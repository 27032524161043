import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { ApiService } from './api.service';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Session } from '../models/session.model';
import { Token } from '../models/token.model';
import { TokenService } from './token.service';
import { User } from '../models/user.model';

const httpOptions = {
  headers: new HttpHeaders({
      'Content-Type': 'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  private currentUserSubject: BehaviorSubject<User>;
  public  currentUser: Observable<User>;
  private user: User;

  constructor(
      private http: HttpClient, 
      private apiService: ApiService,
      private router: Router,
      private tokenService: TokenService) {
      this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
      this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  login(username: string, password: string) { 
      var creds = {
          UserName: username,
          Password: password
      };

      localStorage.setItem('creds', JSON.stringify(creds));    

      return this.http.post<Session>(this.apiService.AuthUrl(), creds, httpOptions)
          .pipe(map(session => {
              if (session && session.token) {
                 this.tokenService = new TokenService(session.token);
                 this.user = new User(session.token, this.tokenService.token);
                localStorage.removeItem('currentUser');
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('currentUser', JSON.stringify(this.user));       
                this.currentUserSubject.next(this.user);
              }
              return session;
          }));
  }

  refresh() {
    var cds = localStorage.getItem('creds');
    return this.http.post<Session>(this.apiService.AuthUrl(), cds, httpOptions)
          .pipe(map(session => {
              if (session && session.token) {
                 this.tokenService = new TokenService(session.token);
                 this.user = new User(session.token, this.tokenService.token);
                localStorage.removeItem('currentUser');
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('currentUser', JSON.stringify(this.user));       
                this.currentUserSubject.next(this.user);
              }
              return session;
          }));
  }

  logout() {
      // remove user from local storage to log user out
      localStorage.removeItem('currentUser');
      this.currentUserSubject.next(null);
      localStorage.removeItem('creds');
  }
}
