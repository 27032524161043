import { Injectable } from '@angular/core';
import { Token } from '../models/token.model';

@Injectable({
  providedIn: 'root'
})
export class TokenService {

  private _token: Token;

  constructor(private raw: string) { 

    if (raw != "empty")
    {
      // extract token payload
      var rawPayload = raw.split('.')[1];
      // decode token payload
      var payload = atob(rawPayload);
      // init token model
      this._token = JSON.parse(payload);
    }
    
  }

  get token(): Token {
    return this._token;
  }
  
}
