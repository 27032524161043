import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HeaderService } from './header.service';
import { ApiService } from './api.service';
import { KeyValue } from '@angular/common';
import { Observable, pipe } from 'rxjs';

declare var require: any;
//import { stringify } from 'json-stringify-safe';
var stringify = require('json-stringify-safe');
import { CompanyService } from './company.service';

@Injectable({
  providedIn: 'root'
})
export class AdminService {

  companies: Roi.Data.Company[];
  filter: string;

  constructor(
    private http: HttpClient,
    private headerService: HeaderService,
    private apiService: ApiService) { }

  ngOnInit() {
  }


  getCompanies(): Observable<KeyValue<string, Roi.Data.Company[]>>{
    return this.http.get<KeyValue<string, Roi.Data.Company[]>>(`${this.apiService.DataUrl()}/companies?$expand=Testers`, this.headerService.authHeader);
    //return this.http.get<KeyValue<string, Roi.Data.Company[]>>(`${this.apiService.DataUrl()}/companies`, this.headerService.authHeader);
  }
  getDevices(): Observable<KeyValue<string, Roi.Data.Device[]>>{
    return this.http.get<KeyValue<string, Roi.Data.Device[]>>(`${this.apiService.DataUrl()}/devices?$orderby=Serial`, this.headerService.authHeader);
  }
  getTesters(): Observable<KeyValue<string, Roi.Data.Tester[]>>{
    return this.http.get<KeyValue<string, Roi.Data.Tester[]>>(`${this.apiService.DataUrl()}/testers?$orderby=Name&$expand=Companies`, this.headerService.authHeader);
  }
  getTests(): Observable<KeyValue<string, Roi.Data.Test[]>>{
    return this.http.get<KeyValue<string, Roi.Data.Test[]>>(`${this.apiService.DataUrl()}/tests?$orderby=Uuid`, this.headerService.authHeader);
  }
  saveDevice(device: Roi.Data.Device){
    this.http.post(`${this.apiService.DataUrl()}/devices`, device, this.headerService.authHeader).subscribe(
      data => {
        console.log("POST Request is successful ", data);
      },
      error  => {
        console.log("Error", error);
      }
    );
  }
  saveCompany(company: Roi.Data.Company){
    this.http.post(`${this.apiService.DataUrl()}/companies`, company, this.headerService.authHeader).subscribe(
      data => {
        console.log("POST Request is successful ", data);
      },
      error  => {
        console.log("Error", error);
      }
    );
  }
  deleteCompany(company: Roi.Data.Company){
    this.http.delete(`${this.apiService.DataUrl()}/companies/${company.Id}`, this.headerService.authHeader).subscribe(
      data => {
        console.log("Delete Request is successful ", data);
      },
      error  => {
        console.log("Error", error);
      }
    );
  }
  postTester(tester: Roi.Data.Tester, company: Roi.Data.Company){

    class TesterCompany {
      //TesterId: Edm.Guid;
      //TesterName: string;
      //TesterPassword: string;
      Tester: Roi.Data.Tester;
      CompanyId: Edm.Guid;
    };

    var testerCompany = new TesterCompany();
    //testerCompany.TesterId = tester.Id;
    //testerCompany.TesterName = tester.Name;
    //testerCompany.TesterPassword = tester.Password;
    testerCompany.Tester = tester;
    testerCompany.CompanyId = company.Id;

    this.http.post(`${this.apiService.DataUrl()}/newtester`, testerCompany, this.headerService.authHeader).subscribe(
      data => {
        console.log("POST Request is successful ", data);

        // this.http.post(`${this.apiService.DataUrl()}/newtester/AddCompanies/${tester.Id}`, companies, this.headerService.authHeader).subscribe(
        //   data => {
        //     console.log("POST Request is successful ", data);
        //   },
        //   error  => {
        //     console.log("Error", error);
        //   }
        // )
      },
      error  => {
        console.log("Error", error);
      }
    );
  }

  patchTester(tester: Roi.Data.Tester){
    this.http.post(`${this.apiService.DataUrl()}/newtester`, tester, this.headerService.authHeader).subscribe(
      data => {
        console.log("Patch Request is successful ", data);
      },
      error  => {
        console.log("Error", error);
      }
    );
  }

  deleteTester(tester: Roi.Data.Tester){
    this.http.delete(`${this.apiService.DataUrl()}/testers/${tester.Id}`, this.headerService.authHeader).subscribe(
      data => {
        console.log("Delete Request is successful ", data);
      },
      error  => {
        console.log("Error", error);
      }
    );
  }
  // addTesterToCompany(tester: Roi.Data.Tester ){
  //   this.http.post(`${this.apiService.DataUrl()}/newtester`, tester, this.headerService.authHeader).subscribe(
  //     data => {
  //       console.log("POST Request is successful ", data);
  //     },
  //     error  => {
  //       console.log("Error", error);
  //     }
  //   );
  // }
}
