import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  constructor() { }

  filter(companies: Roi.Data.Company[]): string{

    var filterString = '?$filter=';

    var count = companies.length;

    for(let company of companies) {
      filterString += `CompanyId eq '${company.Id}'`;
      if (count > 1) { filterString += ' or '; count --;}
    }

    return filterString;
  }
}
