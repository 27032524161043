import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DateTimeService {

  constructor() { }

  formatMDY(date: string): string {
    var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + (d.getDate() + 1),
    year = d.getFullYear();

   // if (month.length < 2) month = '0' + month;
   // if (day.length < 2) day = '0' + day;

    return [month, day, year].join('/');
  }

  toDateTime(date: string): string {
      return new Date(date).toDateString();
  }

  toLong(date: string): string {
      return new Date(date).getTime().toString();
  }
}
