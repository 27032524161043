import { Component, OnInit, ViewChild } from '@angular/core';
import { AdminService } from 'src/app/services/admin.service';
import { AgGridNg2 } from 'ag-grid-angular';
import { SearchService } from 'src/app/services/search.service';
import { ReportService } from 'src/app/services/report.service';
import { NgForm } from '@angular/forms';
import { CompanyService } from 'src/app/services/company.service';
import { ROUTER_INITIALIZER } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';
import { Guid } from '../models/guid.model';
import { NewTester } from '../models/new-tester.model';
import { KeyValue } from '@angular/common';
import { ModalService } from '../modal/modal.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

// import custom validator to validate that password and confirm password fields match
import { MustMatch } from './helpers/must-match.validator';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {

  currentTester: Roi.Data.Tester = null;

  @ViewChild('agGrid', {static: false}) agGrid: AgGridNg2;
  selectedCompanies: Roi.Data.Company[];
  testerCompanies: Roi.Data.Company[];
  companies: Roi.Data.Company[];
  devices: Roi.Data.Device[];
  devicesFiltered: Roi.Data.Device[] = null;
  selectedDevice: Roi.Data.Device = null;
  testers: Roi.Data.Tester[] = null;
  selectedTester: Roi.Data.Tester = null;
  creatingTester: boolean = false;
  testersFiltered: Roi.Data.Tester[] = null;
  tests: Roi.Data.Test[];
  testsFiltered: any = [];
  currentCompany: Roi.Data.Company = null;
  creatingCompany: boolean = false;
  editingCompany: boolean = false;
  companyIdToName: any = {};
  testerIdToName: any = {};
  newPassword: string;
  resetPasswordForm: FormGroup;

  constructor(
    private adminService: AdminService,
    private searchService: SearchService,
    private reportService: ReportService,
    private authService: AuthenticationService,
    private companyService: CompanyService,
    private modalService: ModalService,
    private formBuilder: FormBuilder) { 
      this.agGrid = <AgGridNg2>{};
      this.selectedCompanies = [];
    }

  ngOnInit() {
    this.getCompanies();
    this.getChildren();
    this.initPasswordForm();
    
  }

  initPasswordForm(){
    this.resetPasswordForm = this.formBuilder.group({
      password: ['', [Validators.required, Validators.minLength(6)]],
      confirmPassword: ['', Validators.required]
    }, {
        validator: MustMatch('password', 'confirmPassword')
    });
  }

  getCurrentTester(){
    var user = this.authService.currentUserValue;
    var tester = this.testers.filter(tester => {
      return tester.Name.toLowerCase() == user._token.unique_name.toLowerCase();
    });
    this.currentTester = tester[0];
  }

  filterChildren() {
    var tempDevices = this.devicesFiltered;
    this.devicesFiltered = null;
    var tempTests = this.testsFiltered;
    this.testsFiltered = null;
    var tempTesters = this.testersFiltered;
    this.testersFiltered = null;


    if (this.selectedCompanies.length == 1){
      this.devicesFiltered = this.devices.filter(device => {
          return device.CompanyId.toLowerCase() == this.selectedCompanies[0].Id.toLowerCase();
      });
      this.testsFiltered = this.tests.filter(test => {
        return test.CompanyId.toLowerCase() == this.selectedCompanies[0].Id.toLowerCase();
      });
      this.testersFiltered = this.selectedCompanies[0].Testers;
    }
    else{
      for (var i = 0; i < this.selectedCompanies.length; i++){
        this.devicesFiltered = tempDevices.concat(this.devices.filter(device => {
          return device.CompanyId == this.selectedCompanies[i].Id;
        }));
        this.testsFiltered = tempTests.concat(this.tests.filter(test => {
          return test.CompanyId == this.selectedCompanies[i].Id;
        }));
        this.testersFiltered = tempTesters.concat(this.companies[i].Testers);
      }
    }
    if (this.selectedCompanies.length > 0){
      this.testsFiltered.forEach(test => {
        test['CompanyName'] = this.companyIdToName[test['CompanyId']];
        test['TesterName'] = this.testerIdToName[test['TesterId']];
      });
    }
  }

  getCompanies() {
    this.adminService.getCompanies().pipe().subscribe(t => {
      this.companies = [];
      this.companies = t.value;
      this.companies.forEach(company => {
        this.companyIdToName[company.Id] = company.Name;
      });
    });
    
  }

  getChildren() {
    
    this.adminService.getDevices().pipe().subscribe(t => {
      this.devices = t.value;
    });
    this.adminService.getTesters().pipe().subscribe(t => {
      this.testers = t.value;
      this.testers.forEach(tester => {
        this.testerIdToName[tester.Id] = tester.Name;
      });
      this.getCurrentTester();
    });
    this.adminService.getTests().pipe().subscribe(t => {
      this.tests = t.value;
    });

  }

  selectDevice(device: Roi.Data.Device){
    this.selectedDevice = device;
  }

  deviceSelected(device: Roi.Data.Device): boolean{
    if (this.selectedDevice == null){
      return false;
    }

    return device.Id == this.selectedDevice.Id;
  }

  createTester(){
    this.creatingTester = true;
    this.selectedTester = new NewTester();
  }

  saveTester(){
    if (this.creatingTester)
    {
      this.testersFiltered.push(this.selectedTester);
      this.creatingTester = false;

      this.adminService.postTester(this.selectedTester, this.selectedCompanies[0]);
    }
    else{
      this.adminService.patchTester(this.selectedTester);
    }
    

    this.adminService.getTesters().pipe().subscribe(t => {
      this.testers = t.value;
    });
    this.selectedTester = null;
  }

  get f() { return this.resetPasswordForm.controls; }

  resetPassword(){
    this.modalService.open('passwordModal');
  }

  updatePassword(){
    // stop here if form is invalid
    if (this.resetPasswordForm.invalid) {
      return;
    }
    this.modalService.close('passwordModal');
    this.selectedTester.Password = this.resetPasswordForm.value.password;
    this.saveTester();
    this.initPasswordForm()
  }

  cancelPassword(){
    this.modalService.close('passwordModal');
    this.initPasswordForm();
  }
  
  cancelTester(){
    this.selectedTester = null;
    this.creatingTester = false;
  }

  deleteTester(tester: Roi.Data.Tester){
    if (tester != this.currentTester){
      this.adminService.deleteTester(tester);
      this.testersFiltered = this.testersFiltered.filter(t => {
        return t.Id != tester.Id;
      });
      this.selectedTester = null;

      this.adminService.getTesters();
    }
  }

  selectTester(tester: Roi.Data.Tester){
    this.selectedTester = tester;
  }

  thisTesterSelected(tester: Roi.Data.Tester): boolean{
    if (this.selectedTester == null){
      return false;
    }

    return tester.Id == this.selectedTester.Id;
  }

  testerSelected(): boolean{
    return this.selectedTester != null;
  }

  saveDevice(){
    this.adminService.saveDevice(this.selectedDevice);
    this.selectedDevice = null;

  }

  createCompany(){
    this.currentCompany = new Company();
    this.creatingCompany = true;
  }

  saveCompany(){
    if (this.creatingCompany){
      this.currentCompany.Id = Guid.newGuid();
      this.currentCompany.Testers = [];
      // default tester: add current tester to new company
      this.currentCompany.Testers.push(this.currentTester);
      this.companies.push(this.currentCompany);

      this.authService.refresh();
      this.getCompanies();
    }
    
    
    this.adminService.saveCompany(this.currentCompany);
    this.creatingCompany = false;
    this.editingCompany = false;
    this.selectedCompanies = [];
    //this.getCompanies();
  }

  companySelected(): boolean{
    return this.selectedCompanies.length > 0;
  }

  singleCompanySelected(): boolean{
    return this.selectedCompanies.length === 1;
  }

  editCompany(){
    this.currentCompany = this.selectedCompanies[0];
    this.editingCompany = true;
  }

  deleteCompany(){
    this.adminService.deleteCompany(this.selectedCompanies[0]);
    this.companies = this.companies.filter(c => {
      return c.Id != this.selectedCompanies[0].Id;
    });
    this.selectedCompanies = [];
    //this.getCompanies();
  }

  cancelCompany(){
    this.creatingCompany = false;
    this.editingCompany = false;
  }

  companiesSelected(selectedCompanies: any, allCompanies: any): boolean {
    return selectedCompanies && allCompanies ? selectedCompanies.id === allCompanies.id : selectedCompanies === allCompanies;
  }

  getSelectedTest(){
    const selectedNodes = this.agGrid.api.getSelectedNodes();
    const selectedData = selectedNodes.map( node => node.data );
    //this.reportService.viewReport(selectedData[0].Id);
    this.reportService.viewOldReport(selectedData[0])
  }

  columnDefs = [
    {headerName: 'UUID', field: 'UuId', sortable: true, filter: true, checkboxSelection: true },
    {headerName: 'Date', field: 'DateTime', sortable: true, filter: true },
    {headerName: 'OPID', field: 'OpId', sortable: true, filter: true },
    {headerName: 'DOB', field: 'Dob', sortable: true, filter: true },
    {headerName: 'Tester', field: 'TesterName', sortable: true, filter: true },
    {headerName: 'Company', field: 'CompanyName', sortable: true, filter: true },
  ];

}

class Company implements Roi.Data.Company {
  Id: string;  Name: string;
  Contact: string;
  Address: string;
  City: string;
  State: string;
  Zip: string;
  Phone: string;
  Email: string;
  IsActive: boolean;
  LastUpdate: Date;
  Testers: Roi.Data.Tester[];
  
}