import { BrowserModule } from '@angular/platform-browser';
import { FormsModule }   from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http'; 
import { routing } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { SearchComponent } from './search/search.component';
import { LoginComponent } from './login/login.component';
import { AgGridModule } from 'ag-grid-angular';
import { BasicComponent } from './reports/basic/basic.component';
import { Component, NgModule } from '@angular/core';
import { GaugeModule } from 'angular-gauge';
import { GoogleChartsModule } from 'angular-google-charts';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule, MatIconModule, MatSelectModule, MatToolbarModule, MatListModule, MatGridListModule, MatExpansionModule, MatFormFieldModule, MatInputModule, MatCheckboxModule, MatTreeModule, MatDatepickerModule, MatNativeDateModule, MatProgressSpinnerModule, MatDialogModule} from '@angular/material';
import { AdminComponent } from './admin/admin.component';
import { DeviceComponent } from './admin/device/device.component'
import { ChartsModule } from 'ng2-charts';
import { ProgressComponent } from './progress/progress.component';
import { ModalModule } from './modal/modal.module';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    SearchComponent,
    LoginComponent,
    BasicComponent,
    AdminComponent,
    DeviceComponent,
    ProgressComponent
  ],
  imports: [
    BrowserModule,
    ReactiveFormsModule,
    HttpClientModule,
    routing,
    FormsModule,
    AgGridModule.withComponents([]),
    GaugeModule.forRoot(),
    GoogleChartsModule.forRoot(),
    ChartsModule,
    BrowserAnimationsModule,
    MatButtonModule,
    MatIconModule,
    MatSelectModule,
    MatToolbarModule,
    MatListModule,
    MatGridListModule,
    FlexLayoutModule,
    MatExpansionModule,
    MatFormFieldModule, 
    MatInputModule,
    MatCheckboxModule,
    MatTreeModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    ModalModule
  ],
  providers: [
    {provide: String, useValue: "empty"}
  ],
  bootstrap: [AppComponent],
  entryComponents: [ProgressComponent],
})
export class AppModule { }
