import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from './authentication.service';
import { Session } from '../models/session.model';
import { HeaderService } from './header.service'
import { NgForm } from '@angular/forms';
import { KeyValue } from '@angular/common';
import { DateTimeService } from './date-time.service';
import { ApiService } from './api.service'
import { User } from '../models/user.model';

@Injectable({
  providedIn: 'root'
})
export class SearchService {

  constructor(
    private http: HttpClient,
    private authService: AuthenticationService,
    private headerService: HeaderService,
    private dateTime: DateTimeService,
    private apiService: ApiService){}


    getAll() {
        return this.http.get<KeyValue<string, string>>(`${this.apiService.DataUrl()}/tests`, this.headerService.authHeader);
    }

    filter(search: NgForm){

        var filters: string[] = [];
        var filter: string = '';

        if (search.value.startDate != ""){
            filters.push(`time ge ${this.dateTime.toLong(search.value.startDate)}`);
        }
        if (search.value.endDate != ""){
            filters.push(`time le ${this.dateTime.toLong(search.value.endDate)}`);
        }
        if (search.value.dob != ""){
            filters.push(`dob eq '${this.dateTime.formatMDY(search.value.dob)}'`);
        }
        if (search.value.uuid != ""){
            filters.push(`uuid eq '${search.value.uuid}'`);
        }
        if (search.value.opid != ""){
            filters.push(`opid eq '${search.value.opid}'`);
        }


        if (filters.length > 0){
            filter = '?$filter=';
            var count: number = 1;
            filters.forEach(f => {
                if (count == filters.length){
                    filter += f;
                }
                else{
                    filter += f + ' and '
                    count++;
                }
            });
        }
        //console.log(search);
        return this.http.get<KeyValue<string,string>>(`${this.apiService.DataUrl()}/tests${filter}`, this.headerService.authHeader);
    }
}