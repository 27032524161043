import { Component, ViewChild, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { NgForm } from '@angular/forms';
import { SearchService } from '../services/search.service';
import { DateTimeService } from '../services/date-time.service'
import { ReportService } from '../services/report.service';
import { AgGridNg2 } from 'ag-grid-angular';
import { AdminService } from '../services/admin.service';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {
  @ViewChild('agGrid', {static: false}) agGrid: AgGridNg2;
  tests: any;
  rowData: any;
  companyIdToName: any = {};
  testerIdToName: any = {};
  companies: Roi.Data.Company[];
  testers: Roi.Data.Tester[];

  constructor(
    private searchService: SearchService,
    private dateTime: DateTimeService,
    private reportService: ReportService,
    private adminService: AdminService
    ){ 
      this.agGrid = <AgGridNg2>{};
    }

  ngOnInit() {
    
    this.getCompanies();
  }

  // onSubmit(f: NgForm) {
  //   this.searchService.filter(f).pipe(first()).subscribe(t => {
  //       this.rowData = t.value;
  //   });
  // }

  getCompanies() {
    this.adminService.getCompanies().pipe().subscribe(t => {
      this.companies = [];
      this.companies = t.value;
      this.companies.forEach(company => {
        this.companyIdToName[company.Id] = company.Name;
      });
      this.getTesters();
    });
    
  }
  getTesters(){
    this.adminService.getTesters().pipe().subscribe(t => {
      this.testers = t.value;
      this.testers.forEach(tester => {
        this.testerIdToName[tester.Id] = tester.Name;
      });
      this.getTests();
    });
  }

  getTests(){
    this.searchService.getAll().pipe(first()).subscribe(t => {
      this.rowData = t.value;
      this.rowData.forEach(test => {
        test['CompanyName'] = this.companyIdToName[test['CompanyId']];
        test['TesterName'] = this.testerIdToName[test['TesterId']];
      });
    });
  }
  

  getSelectedTest(){
    const selectedNodes = this.agGrid.api.getSelectedNodes();
    const selectedData = selectedNodes.map( node => node.data );
    this.reportService.viewOldReport(selectedData[0]);
    //this.reportService.viewReport(selectedData[0].Id);
  }

  columnDefs = [
    {headerName: 'UUID', field: 'UuId', sortable: true, filter: true, checkboxSelection: true },
    {headerName: 'Date', field: 'DateTime', sortable: true, filter: true },
    {headerName: 'OPID', field: 'OpId', sortable: true, filter: true },
    {headerName: 'DOB', field: 'Dob', sortable: true, filter: true },
    {headerName: 'Tester', field: 'TesterName', sortable: true, filter: true },
    {headerName: 'Company', field: 'CompanyName', sortable: true, filter: true },
  ];

}
