import { Component, OnInit, ViewChild } from '@angular/core';
import { ReportService } from 'src/app/services/report.service';
import { first } from 'rxjs/operators';
import { GoogleChartComponent } from 'angular-google-charts';
import { ReportData } from 'src/app/models/report-data.model';



@Component({
  selector: 'app-basic',
  templateUrl: './basic.component.html',
  styleUrls: ['./basic.component.scss']
})



export class BasicComponent implements OnInit {
  rep: ReportData

  

  @ViewChild('googlechart', {static: false}) 
    googlechart: GoogleChartComponent;
    chart = {
      type: 'Gauge',
      options: {
        width: 400,
        height: 120,
        greenFrom: 0,
        greenTo: 75,
        redFrom: 90,
        redTo: 100,
        yellowFrom: 75,
        yellowTo: 90,
        minorTicks: 5
      }
    };
  

  constructor(private reportService: ReportService) {
    this.rep = new ReportData();
   }

  ngOnInit() {
    this.rep = new ReportData();
    this.reportService.getReport().pipe(first()).subscribe(r => {
      this.rep = r.ReportData;
    });
  }

}
