import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from './authentication.service';
import { User } from '../models/user.model';
import { TokenService } from './token.service';
import { Session } from '../models/session.model';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {
  date: Date;
  now: number;
    private user: User;
    private session: Session;

  constructor( 
    private authenticationService: AuthenticationService,
    private tokenService: TokenService,
    private router: Router ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    const user = this.authenticationService.currentUserValue;

    this.now = new Date().getTime() / 1000;

    if (user !== null) {
      // check token value
      if ( user._tokenExpires > this.now ){
            return true;
      }
    }
    // not logged in so redirect to login page with the return url
    this.router.navigate(['/login'], { queryParams: { returnUrl: state.url }});
    return false;
  }
}
