import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor() { }

  AuthUrl() : string {
    return "https://roidata.azurewebsites.net/jwt/authenticate";
  }
  DataUrl() : string {
     return "https://roidata.azurewebsites.net";
    //return "http://localhost:49999/"
  }
  CosmosUrl() : string {
    //return "https://roi.documents.azure.com:443/dbs/-id+AA==/colls/-id+AN3VoHw=/docs"
    return "https://roi.documents.azure.com:443";
  }
  CosmosKey() : string {
    return "mdKXWHu7FgVhDaFxTnUzgn8FjikFTssQh7Hx0Y8kIn4M03Pa9PRgmgQcNPMQMcRvgLyt55gXcL4GwLshFGs5bg==";
  }
}
