import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { HeaderService } from './header.service';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class ReportsService {

  constructor(
    private http: HttpClient,
    private headerService: HeaderService,
    private apiService: ApiService
  ) { }

  getInjurys(): Observable<any>{
    return this.http.get<any>(`${this.apiService.DataUrl()}/reports`, this.headerService.authHeader);
  }

  getInjurys0(): Observable<any>{
    return this.http.get<any>(`${this.apiService.DataUrl()}/datareports`, this.headerService.authHeader);
  }

  getInjurys1(): Observable<any>{
    return this.http.get<any>(`https://roi.documents.azure.com:443/reports()?$filter=InjuryFlag gt 89`, this.headerService.cosmosAuthHeader);
  }

  getPossibleInjurys(): Observable<string[]>{
    return this.http.get<string[]>(`${this.apiService.DataUrl()}/reports?$filter=InjuryFlag gt 89&$select=UuId`, this.headerService.authHeader);
  }
}
